@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?o5gyuf');
  src:  url('fonts/icomoon.eot?o5gyuf#iefix') format('embedded-opentype'),
  url('fonts/icomoon.ttf?o5gyuf') format('truetype'),
  url('fonts/icomoon.woff?o5gyuf') format('woff'),
  url('fonts/icomoon.svg?o5gyuf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-heart:before {
  content: "\f004";
}
.icon-star:before {
  content: "\f005";
}
.icon-star-o:before {
  content: "\f006";
}
.icon-check-circle:before {
  content: "\f058";
}
.icon-info-circle:before {
  content: "\f05a";
}
.icon-check-circle-o:before {
  content: "\f05d";
}
.icon-heart-o:before {
  content: "\f08a";
}
