.navbar-nav .dropdown-menu { padding: 0; }
.form-control:disabled,
.form-control[readonly] { color: #95aac9; }
.table td { vertical-align: middle; }
.card.card-sm .card-body,
.card.card-sm .card-header { padding: 1.0rem; min-height: auto; }
.card.card-xs .card-body,
.card.card-xs .card-header { padding: 0.5rem; min-height: auto; }

#cinemaModal .list > div:last-child { border-bottom: 0 !important; }
.tix-bg { background-size: cover; background-position: 50% 50%; background-image: url(../img/tix-banner.png) }
.tix-bg .description a { color: #E63757 !important; }
.tix-bg .description a:hover,
.tix-bg .description a:focus { color: #b91735 !important; }
.bg-black { background-color: #000; }
.no-link { pointer-events: none; }

.payment-gateway { padding: 5px; border: 1px solid #fff; border-radius: 4px; }
.payment-gateway:hover { cursor: pointer; }
.payment-gateway .pg-checked { float: left; /*margin: 5px 0;*/ display: none; font-size: 18px; }
.payment-gateway:hover .pg-checked { color: #f05050; }
.payment-gateway .pg-info { padding-left: 26px; }
.payment-gateway .pg-info > img { width: 28px; border-radius: 4px; margin-right: 5px; }
.payment-gateway .pm-description { margin-left: 37px; color: #95aac9; font-size: 0.8125rem; display: block; }

.payment-gateway.disabled { opacity: 0.4; }
.payment-gateway.active { border-color: #adadad; background-color: #edf1f2; }
.payment-gateway.active .pg-checked { display: block; color: #f05050; }

.payment-gateway.pm-bank-transfer { padding-bottom: 10px; }

.btn-showtime { margin-bottom: 4px; line-height: 14px; width: 56px; height: 38px; padding: 4px 0; display: inline-flex; flex-direction: column; justify-content: center; }
.btn-showtime .amenity { color: #95aac9; font-size: 11px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

.bct-announce { max-height: 40px; }

.js-video {
    height: 0;
    padding-top: 25px;
    padding-bottom: 67.5%;
    position: relative;
    overflow: hidden;
}

.js-video.widescreen {
    padding-bottom: 57.25%;
}

.js-video embed, .js-video iframe, .js-video object, .js-video video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

#videoModal .modal-body { padding: 0; }

.is-sponsored { position: relative; }
.is-sponsored .sponsored {
    position: absolute; margin: 0 auto; left: 1px; right: 1px; text-align: center;
    background: rgba(0,0,0, 0.6); color: #fff; padding: 2px 0;
    border-radius: .375rem .375rem 0 0;
}

.card-critic { border-color: #12263f; }
.card-sponsored { border-color: #D31702; }
.card-sponsored .sponsored {
    position: absolute;left: 0;top: 0;
    background-color: #ebccd1;padding: 0 15px;font-size: 9px;color: #a94442;
    border-radius: 4px 0px 4px 0; z-index: 9;
}

.item .play-icon {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    font-size: 60px;
    color: #fff;
    opacity: 0.7;
    line-height: 1;
}
.item:hover .play-icon,
.item:hover .remove-icon { opacity: 1; }
.item .play-icon.liked { color: #e63757; }
.item .remove-icon {
    position: absolute;
    top: 0;
    font-size: 24px;
    color: #fff;
    opacity: .7;
    right: 5px;
}
.item .btn-actions { position: absolute; top: 5px; right: 5px;  }
.item .btn-actions .btn { line-height: 1; padding: 2px 4px; }

.featured-movie { background-position: 50% 50%; background-size: cover; }
.featured-movie h1 a:hover,
.featured-movie h1 a:focus { color: #b91735; }

.slick-grid { display: none; }
.slick-grid.slick-initialized { display: block; }
.slick-grid .slick-dots { bottom: -10px; margin: 0; }
.slick-grid .slick-dots li { margin: 0; }

.grid .item p i.fe { line-height: 1 !important; }

.list-group-item.btn-select-cinema { cursor: pointer; }

.sticky-movie-bars.sticky { position: fixed; z-index: 2; width: 100%; top: 0; left: 0; border-radius: 0; border-width: 0 0 1px 0px; }

.spoil-alert .review-content { display: none; }

.avatar .sprite.ticketed-mini { right: -2px; bottom: -2px; left: auto; top: auto; }

.card .sprite { left: -4px; }
.sprite { background-image: url(../img/sprite-min.png); background-repeat: no-repeat; position: absolute; left: -3px; top: 6px;  z-index: 1; }
.sprite.sneak { width: 70px; height: 19px; background-position: 0 0; }
.sprite.hot { width: 71px; height: 19px; background-position: -89px 0; }
.sprite.new { width: 71px; height: 19px; background-position: -179px 0; }
.sprite.soon { width: 71px; height: 19px; background-position: -269px 0; }
.sprite.featured { width: 92px; height: 19px; background-position: -361px 0; }
.sprite.ticketing { width: 66px; height: 19px; background-position: -476px 0; }
.sprite.ticketed { width: 103px; height: 19px; background-position: -565px 0; }
.sprite.ticketed-mini { width: 20px; height: 19px; background-position: -692px 0; }

.language-en .sprite {  background-image: url(../img/sprite-en-min.png); }
.language-en .sprite.soon { width: 70px; }
.language-en .sprite.ticketing { width: 106px; background-position: -476px 0; }
.language-en .sprite.ticketed { width: 138px; background-position: -605px 0; }
.language-en .sprite.ticketed-mini { background-position: -768px 0; }

#msgModal .modal-content {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border: 0;
    background-color: transparent;
}
#msgModal .modal-content .fe { font-size: 135px; }

.btn-do-movie-rate i,
.btn-do-movie-like i { font-size: 11px }
.btn-do-movie-rate.rated i { color: #f6c343; }
.btn-do-movie-like.liked i { color: #e63757; }

.avatar-verified { position: relative; }
.avatar-verified .avatar-img {
    -webkit-mask-image: url(../theme/img/masks/avatar-status.svg);
    mask-image: url(../theme/img/masks/avatar-status.svg);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
}
.avatar-verified::before {
    font-family: 'icomoon';
    content: "\f058";
    position: absolute;
    bottom: -2px;
    right: 2px;
    font-size: 12px;
}
.avatar-xs.avatar-verified::before { bottom: -1px; right: 1px; font-size: 7px; }
.avatar-sm.avatar-verified::before { bottom: -3px; right: 1px; font-size: 11px; }
.avatar-lg.avatar-verified::before { bottom: -2px; right: 3px; font-size: 16px; }

.card-critic .avatar-verified { color: #12263f; }
.badge-critic { position: absolute; width: 90px; top: -61px;left: -9px; }

.summary-rating-percentage { font-size: 3rem; }
.card-info { position: absolute; padding: 0px 3px; border-radius: 7px 0; font-size: 10px; background-color: #12263f; color: #fff !important; z-index: 1; }

.page-load-status { display: none; color: #95aac9; text-align: center; margin: 1.5rem 0; }

.sponsored-cineplex { background-repeat: no-repeat; background-position: right; }

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {
    .sprite { background-image: url(../img/sprite@2x-min.png); background-size: 712px 19px; }
    .language-en .sprite { background-image: url(../img/sprite-en@2x-min.png); background-size: 788px 19px; }
}

@media (max-width: 767px) {
    .payment-gateway .pg-checked { float: right; }
    .payment-gateway .pg-info { padding: 0; }

    .badge-critic { width: 80px; top: -54px; left: -5px; }
}

.table-nowrap td.unset-nowrap{
    white-space: unset;
}

.slick-grid .ticket-label {
    position: absolute;
    bottom: 0px;
    background-color: #d31602;
    color: #fff;
    text-align: center;
    width: 100%;
    margin-bottom: 0px;
    cursor: pointer;
}

#topnav .navbar-collapse {
    z-index: 101;
}

.adsbygoogle-wrap {
    opacity: 0 !important;
    position: fixed;
    z-index: 100;
    top: 205px;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
}

#_no-clickjacking-0, body .adsbygoogle-wrap, #_no-clickjacking-0 .adsbygoogle {
    opacity: 0 !important;
}

.adsbygoogle-wrap.custom-ads {
    top: 105px;
}

@media (max-width: 767px) {
    .adsbygoogle-wrap {
        top: 150px;
    }

    .adsbygoogle-wrap.custom-ads {
        top: 100px;
    }
}

.moveek-zalo-qr {
    max-width: 60px;
}